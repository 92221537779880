<template>
  <div class="content">
    <h2 class="title" data-aos="fade-down" data-aos-duration="3000">Тарифы</h2>
    <p class="tarrifes-subtitle" data-aos="fade-right" data-aos-duration="1000">
      Наше агентство
      <b>
        специализируется на сопровождении деятельности медицинских и научных
        сообществ</b
      >, в рамках которой требования к рекламе существенно отличаются от
      стандартных онлайн-объявлений. Мы осознаем сложности и понимаем нюансы,
      сопутствующие соблюдению нового законодательства в области медицинской
      просветительской деятельности и готовы поделиться опытом.
    </p>
    <div class="tarif-table">
      <div class="options-description">
        <div></div>
        <p>Подписание соглашения о неразглашении (NDA) на срок его действия</p>
        <p class="column-color border-left">Регистрации креативов (в месяц)</p>
        <p>Передача данных ОРД</p>
        <p class="column-color border-left">Получение и передача токена</p>
        <p>Контроль разметки публикации (мы предоставляем вам готовую разметку и
        инструктируем по ее размещению)</p>
        <p class="column-color border-left">Подготовка и отправка отчёта и акта в ЕРИР (02)</p>
        <p>Поддержка менеджера</p>
        <div></div>
    </div>
    <div class="tarif-options" v-for="(tarif, index) in tariffs" :key="index">
      <div class="tarif-head">
        <div class="tarif-discount">
          <p>{{ tarif.discount[0] }}</p>
          <p>{{ tarif.discount[1] }}</p>
        </div>
        <p class="tarif-title">{{ tarif.title }}</p>
        <p class="tarif-oldcost">{{ tarif.old_cost }}</p>
        <p class="tarif-cost">{{ tarif.cost }} ₽</p>
      </div>
      <div class="tarif-item"><p>Подписание соглашения о неразглашении (NDA) на срок его действия</p>
        <img class="icon" src="/check.svg" alt="галочка">
      </div>
      <div class="tarif-item column-color"><p>Регистрации креативов (в месяц)</p>{{ tarif.option }}</div>
      <div class="tarif-item"><p>Передача данных ОРД</p>
        <img class="icon" src="/check.svg" alt="галочка">
      </div>
      <div class="tarif-item column-color"><p>Получение и передача токена</p>
        <img class="icon" src="/check.svg" alt="галочка">
      </div>
      <div class="tarif-item"><p>Контроль разметки публикации (мы предоставляем вам готовую разметку и
        инструктируем по ее размещению)</p>
        <img class="icon" src="/check.svg" alt="галочка">
      </div>
      <div class="tarif-item column-color"><p>Подготовка и отправка отчёта и акта в ЕРИР (02)</p>
        <img class="icon" src="/check.svg" alt="галочка">
      </div>
      <div class="tarif-item"><p>Поддержка менеджера</p>{{ tarif.period }}</div>
      <div class="tarif-item tariffes-bottom">
      <a href="#formInput" class="button">Заказать</a>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import axios from 'axios'

export default {
  name: 'TheTarrifes',
  setup () {
    const tariffs = ref([])
    const options = ref([30, 50, 100])
    const period = ref(['1 месяц', '1 месяц', 'На всё время действия договора'])
    const getTarrifs = async () => {
      try {
        const response = await axios.get('https://rdapi.medeva.agency/api/v1/info/tariff')
        if (response.data.status === 'ok') {
          tariffs.value = response.data.data.map((obj, index) => {
            obj.cost = (obj.cost / 100).toLocaleString('ru')
            obj.old_cost = (obj.old_cost / 100).toLocaleString('ru')
            obj.discount = (obj.discount.split(';')).filter(Boolean)
            return { ...obj, option: options.value[index], period: period.value[index] }
          })
        } else {
          return null
        }
      } catch (err) {
        throw new Error(err)
      }
    }

    getTarrifs()
    return {
      tariffs
    }
  }
}
</script>
<style scoped>
.tarrifes-subtitle {
  max-width: 69rem;
  font-size: 18px;
}
.tarif-table {
  display: grid;
  grid-template-columns: 22rem 1fr 1fr 1fr;
  font-size: 18px;
  margin: 5rem 0;
}
.tarif-table div *{
  margin: 0 auto;
}
/**/
.tarif-options:nth-child(2), .tarif-options:nth-child(4){
  margin-top: 1rem;
}
/**/
.tarif-options:nth-child(2) .tarif-item{
  background-image: linear-gradient(rgba(31, 185, 139, 0.05) 100%, transparent);
}
.tarif-options:nth-child(2) .icon{
  background: #1FB98B;
}
.tarif-options:nth-child(4) .icon{
  background: #D0A406;
}
.tarif-options:nth-child(3){
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}
.tarif-options:nth-child(4) .tarif-item{
  background-image: linear-gradient(rgba(208, 164, 6, 0.05) 100%, transparent);
}
.tarif-options div:nth-child(n+2):nth-child(-n+9) {
  display: grid;
  align-content: center;
}
.tarif-options div:nth-child(2){
  min-height: 4.75rem;
}
.tarif-options div:nth-child(n+3):nth-child(-n+5) {
  min-height: 2.25rem;
}
.tarif-options div:nth-child(n+6):nth-child(-n+9) {
  min-height: 6rem;
}
.tarif-options div:nth-child(n+7):nth-child(-n+8) {
  min-height: 3.5rem;
}
.tarif-item {
  text-align: center;
  border-left: 1px solid #C3C6D1;
  border-right: 1px solid #C3C6D1;
  padding: 1rem 2rem 1rem 2rem;
  font-size: 16px;
  font-weight: 600;
}
.tarif-item p {
  display: none;
}
.tarif-title {
  font-size: 18px;
  padding-top: 2rem;
}
.options-description p {
  padding: 1.5rem 2rem 1.5rem 2rem;
  font-size: 16px;
  line-height: 20px;
}
.options-description div {
  min-height: 11rem;
}
.column-color {
  background: rgba(92, 95, 106, 0.07);
}
.border-left {
  background: rgba(92, 95, 106, 0.07);
  border-radius: 15px 0 0 15px;
}
.tarif-cost {
  font-size: 36px;
  font-weight: 600;
}
.tarif-cost::after {
  content: '/мес';
  font-size: 18px;
  padding: 0 0.5rem;
}
.tarif-oldcost {
  text-decoration: line-through;
  display: none;
}
.tarif-head {
  color: white;
  background: #127fec;
  border-radius: 25px 25px 0 0;
  text-align: center;
  min-height: 10rem;
}
.tarif-options:nth-child(2) .tarif-head {
  background: #1FB98B;
  border-radius: 25px 0 0 0;
}
.tarif-options:nth-child(3) .tarif-head{
  min-height: 11rem;
}
.tarif-options:nth-child(4) .tarif-head {
  background: #D0A406;
  border-radius: 0 25px 0 0;
}
/* discount */
.tarif-discount {
  max-height: 3rem;
}
.tarif-discount p:nth-child(1):not(:empty){
  position: relative;
  padding: 0.5rem 3rem 0.5rem 0.5rem;
  background:  rgba(255, 255, 255, 0.25);
}
.tarif-discount p:nth-child(2):not(:empty){
  font-size: 16px;
  position: relative;
  font-weight: 600;
  color: black;
  background: url(@/assets/images/discount-icon.svg) no-repeat center;
  padding-top: 0.7rem;
  width: 50px;
  height: 53px;
  left: 6rem;
  top: -2.45rem;
}
.tarif-discount:not(:empty) {
  padding: 0;
  font-size: 14px;
}
/* end discount */
.icon {
  background: #127fec;
  border-radius: 8px;
  padding: 7.4px 5.81px;
}
.tariffes-bottom{
  border-radius: 0 0 25px 25px;
  border-bottom: 1px solid #C3C6D1;
  padding: 2rem 0;
}
@media (max-width: 1200px) {
  .tarif-options div:nth-child(2){
    min-height: 6rem;
  }
  .tarif-options div:nth-child(n+3):nth-child(-n+5) {
    min-height: 3.5rem;
  }
  .tarif-options div:nth-child(6) {
    min-height: 8.5rem !important;
  }
  .tarif-options div:nth-child(7) {
    min-height: 4.75rem !important;
  }
  .tarif-options div:nth-child(8):nth-child(-n+9) {
    min-height: 5rem;
  }
  .tarif-table {
    grid-template-columns: 15rem 1fr 1fr 1fr;
  }
}
@media(max-width: 997px){
  .tarif-options:nth-child(2), .tarif-options:nth-child(4) {
    margin-top: 0;
  }
  .tarif-options:nth-child(3) .tarif-head {
    min-height: 10rem;
  }
  .tarif-table{
    grid-template-columns: repeat(2, 1fr);
    grid-column-start: 2;
    grid-gap: 1rem;
  }
  .tarif-options:nth-child(3){
    box-shadow: none;
    padding-bottom: 0;
  }
  .tarif-options:nth-child(4){
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .tarif-head{
    border-radius: 25px 25px 0 0 !important;
  }
  .tarrifes-subtitle{
    max-width: 45rem;
  }
  .options-description{
    display: none;
  }
  .tarif-item{
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
  .tarif-item p{
    display: flex;
    text-align: left;
    margin: 0;
  }
  .tariffes-bottom {
    grid-template-columns: 1fr;
  }
  .tarif-discount {
    max-height: 2.4rem;
  }
  .tarif-discount p:nth-child(2):not(:empty) {
    left: 8rem;
  }
}
@media(max-width: 576px) {
  .tarif-table{
    grid-template-columns: 1fr;
  }
  .tarif-discount p:nth-child(2):not(:empty) {
    left: 5.5rem;
  }
  .tarrifes-subtitle{
    width: 100%;
  }
  .tarif-options:nth-child(4){
    grid-column-start: 1;
    grid-column-end: 1;
  }
}
</style>
