<template>
  <div class="content">
    <h2 class="title" data-aos="fade-down" data-aos-duration="3000">
      С 1 сентября 2023 года <br />
      <span class="color-red">введены штрафы</span>
      за нарушение маркировки
    </h2>
    <div class="fines">
      <div class="fines-card" data-aos="zoom-in-up" data-aos-duration="1000">
        <p class="fines-card__text">
          Нарушения в предоставлении данных в ЕРИР (например, непредоставление
          информации о рекламе, нарушение сроков подачи информации или
          предоставление недостоверной информации) могут повлечь следующие
          штрафы:
        </p>
        <div class="fines-conditions">
          <p class="fines-card__price">30 000 - 100 000 ₽</p>
          <p class="fines-card__price-text">должностным лицам</p>
        </div>
        <div class="fines-conditions">
          <div class="fines-card__price">200 000 - 500 000 ₽</div>
          <div class="fines-card__price-text">юридическим лицам</div>
        </div>
      </div>

      <div class="fines-card" data-aos="zoom-in-down" data-aos-duration="1000">
        <p class="fines-card__text">
          Нарушения, связанные с идентификатором рекламы (токеном), такие как
          неправильное размещение токена на рекламе или его отсутствие, могут
          повлечь следующие штрафы:
        </p>
        <div class="fines-conditions">
          <p class="fines-card__price">100 000 - 200 000 ₽</p>
          <p class="fines-card__price-text">должностным лицам</p>
        </div>
        <div class="fines-conditions">
          <p class="fines-card__price">200 000 - 500 000 ₽</p>
          <p class="fines-card__price-text">юридическим лицам</p>
        </div>
      </div>
    </div>
    <div class="alert" data-aos="fade-right" data-aos-duration="1000">
      <img src="@/assets/images/icons/alert.svg" alt="[!]" />
      <p class="alert-text">
        Если рекламодатель совершил несколько нарушений одновременно, штрафы
        могут суммироваться. <br>Например, <span class="color-red">юридическому лицу придется выплатить до
        2,5 миллионов рублей</span> за 5 рекламных объявлений без <br>идентификатора
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FirstBlockFines'
}
</script>
<style scoped>
.fines{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
}
.fines-card {
  padding: 1rem 1.5rem 2rem 1.5rem;
  border: 1px solid #c3c6d1;
  color: #333333;
  border-radius: 30px;
}
.fines-card__text {
  max-width: 31.5rem;
  padding: 1.5rem 0;
  line-height: 30px;
}
.fines-conditions{
  display: grid;
  grid-template-columns: 19.5rem 12rem;
}
.fines-card__price {
  font-size: 26px;
  font-weight: 600;
}
.fines-card__price-text {
  padding-top: 0.8rem;
  text-align: right;
}
.alert{
  margin: 2rem 0 4rem 0;
  display: grid;
  grid-template-columns: 6rem 1fr;
}
.alert-text{
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
@media(max-width: 1440px){
  .fines-conditions{
    grid-template-columns: 2fr 1fr;
  }
  .fines-card{
    padding: 1rem 1.5rem;
  }
}
@media(max-width: 991px) {
  .fines {
    grid-template-columns: 1fr;
    grid-row-gap: 1.5rem;
    grid-column-gap: 0;
  }
  .fines-card__text{
    max-width: 100%;
  }
}
@media(max-width: 576px){
  .fines-conditions {
    grid-template-columns: 1fr;
  }
  .fines-card{
    padding: 0.5rem 1.5rem;
  }
  .fines-card__price {
    font-size: 22px;
  }
  .fines-card__price-text{
    padding-top: 0;
    padding-bottom: 0.8rem;
    text-align: left;
  }
  .title br{
    display: none;
  }
  .alert{
    grid-template-columns: 1fr;
  }
  .alert img{
    width: 50px;
    margin-bottom: 1.5rem;
  }
  .alert-text br:last-child{
    display: none;
  }
}
</style>
