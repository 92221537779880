<template>
  <div class="content">
    <div class="info-grid">
      <div class="info" @click.prevent="toggleInfo(index)" v-for="(item, index) in info" :key="index" data-aos="fade-down" data-aos-duration="1000">
        <div class="info-title" :class="{ opened: item.isOpen, closed: !item.isOpen}">
        <p>{{item.title}}</p>
        <svg v-show="!item.isOpen" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" alt="иконка плюса">
          <path d="M21.2109 0H8.78906C3.935 0 0 3.935 0 8.78906V21.2109C0 26.065 3.935 30 8.78906 30H21.2109C26.065 30 30 26.065 30 21.2109V8.78906C30 3.935 26.065 0 21.2109 0Z" fill="#475F6F"/>
          <path d="M22.0312 13.5352H16.4648V7.96875C16.4648 7.15957 15.8092 6.50391 15 6.50391C14.1908 6.50391 13.5352 7.15957 13.5352 7.96875V13.5352H7.96875C7.15957 13.5352 6.50391 14.1908 6.50391 15C6.50391 15.8092 7.15957 16.4648 7.96875 16.4648H13.5352V22.0312C13.5352 22.8404 14.1908 23.4961 15 23.4961C15.8092 23.4961 16.4648 22.8404 16.4648 22.0312V16.4648H22.0312C22.8404 16.4648 23.4961 15.8092 23.4961 15C23.4961 14.1908 22.8404 13.5352 22.0312 13.5352Z" fill="white"/>
        </svg>
        <svg v-show="item.isOpen" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.2109 0H8.78906C3.935 0 0 3.935 0 8.78906V21.2109C0 26.065 3.935 30 8.78906 30H21.2109C26.065 30 30 26.065 30 21.2109V8.78906C30 3.935 26.065 0 21.2109 0Z" fill="#475F6F"/>
          <path d="M21.0076 18.9362L17.0716 15.0002L21.0076 11.0641C21.5798 10.4919 21.5798 9.56468 21.0076 8.99251C20.4355 8.42033 19.5082 8.42033 18.936 8.99251L15 12.9286L11.064 8.99251C10.4918 8.42033 9.56453 8.42033 8.99235 8.99251C8.42018 9.56468 8.42018 10.4919 8.99235 11.0641L12.9284 15.0002L8.99235 18.9362C8.42018 19.5084 8.42018 20.4356 8.99235 21.0078C9.56453 21.58 10.4918 21.58 11.064 21.0078L15 17.0718L18.936 21.0078C19.5082 21.58 20.4355 21.58 21.0076 21.0078C21.5798 20.4356 21.5798 19.5084 21.0076 18.9362Z" fill="white"/>
        </svg>
        </div>
        <Vue3SlideUpDown v-model="item.isOpen" :duration="3000">
          <div class="info-description" v-if="item.isOpen" v-html="item.description"></div>
        </Vue3SlideUpDown>
      </div>
    </div>
    <div id="formInput"></div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

const info = ref([
  {
    title: 'Нам потребуется',
    description: '<ol>' + '<li>' + 'Данные о договоре с заказчиком, сам креатив, его текст, данные о площадке.' + '</li>' +
    '<li>' + 'Акт между ним и его заказчиком, не позднее 30 дней после публикации рекламы для подготовки отчетности за Вас.' + '</li>' + '</ol>'
  },
  {
    title: 'Дополнительно',
    description: 'Иногда необходимо продлить срок действия рекламного креатива на более чем один месяц. ' +
    'В таких случаях требуется ежемесячное представление отчетности по данному креативу. Для этого необходимо пересмотреть и подготовить акт, ' +
    'а также дополнительно предоставить отчетность в ОРД. Стоимость одного представления отчетности составляет 500 рублей.'
  }
])

const toggleInfo = (index) => {
  info.value[index].isOpen = !info.value[index].isOpen
}
</script>
<script>
export default {
  name: 'TheInfo'
}
</script>
<style scoped>
.info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  align-items: start;
}
.info{
  align-items: center;
}
.info-title {
  display: grid;
  grid-template-columns: 1fr 1fr;
  cursor: pointer;
  align-items: center;
  background: #f2f3f7;
  border-radius: 25px;
  padding: 1.8rem 1.8rem 0 1.8rem;
}
.opened::after {
  content: '';
  background: #c3c6d1;
  height: 1px;
  width: 200%;
}
.info-title p {
  padding-bottom: 1.8rem;
}
.opened {
  position: relative;
  z-index: 2;
  border-radius: 25px 25px 0 0;
}
.closed {
  animation: transform 8s ease;
}
.info-title:hover, .info-title svg:hover path:nth-child(1) {
  color: #127FEC;
  fill: #127FEC;
  transition: 0.2s;
}
.info p {
  font-size: 22px;
  font-weight: 600;
  max-height: 10rem;
}
.info img {
  margin: 0 0 0 auto;
}
.info svg{
  margin: 0 0 0 auto;
  padding-bottom: 1.8rem;
}
.info-description{
 /* animation: fade 2s ease;*/
  padding: 1.8rem;
  font-size: 18px;
  grid-area: 31rem;
  background: #f2f3f7;
  border-radius: 0 0 25px 25px;
}
@keyframes transform {
  0% {
    border-radius: 25px 25px 0 0;
  }
  25% {
    border-radius: 25px 25px 15px 15px;
  }
  50% {
    border-radius: 25px 25px 20px 20px;
  }
  75% {
    border-radius: 25px 25px 25px 25px;
  }
}
@keyframes fade {
  0% {
    transform: translateY(-20%);
    opacity: 0;
 }
 50% {
    transform: translateY(0);
    opacity: 1;
 }
}
@media(max-width: 1440px) {
  .info-title{
    grid-template-columns: 3fr 1fr;
  }
  .opened::after {
    content: '';
    background: #c3c6d1;
    height: 1px;
    width: 133%;
  }
}
@media(max-width: 640px){
  .info-grid{
    grid-template-columns: 1fr;
  }
  .info p {
    font-size: 20px;
  }
  .ingo svg {
    width: 20px;
  }
}
</style>
