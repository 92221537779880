<template>
  <div class="main-card">
    <div class="content">
      <div class="card" data-aos="zoom-in" data-aos-duration="1000">
        <h2 class="card-title">
          По общему правилу маркировать необходимо все объявления и публикации,
          <span class="color-red underline">включая платные и бесплатные</span>,
          которые рекламируют продукт или услугу на любых площадках в сети
          Интернет.
        </h2>
        <p class="card-text">
          Нововведения распространяются на всех участников рекламного рынка,
          включая рекламодателей, распространителей рекламы и операторов
          рекламных систем. Все они обязаны предоставлять информацию о рекламе,
          включая данные о целевой аудитории и затратах.
        </p>
      </div>
      <div class="card-button" id="tariffesTitle"><a href="#tariffesTitle" class="button">Заказать маркировку</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainCard'
}
</script>

<style scoped>
.main-card {
  background: linear-gradient(to top, #fff 60%, #f2f3f7 40%);
  padding-top: 5.5rem;
  margin-bottom: 2rem;
}
.card {
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 30px 50px 0px #00000040;
  margin: 0 0 4.5rem 0;
  padding: 2rem 3rem;
  border-top: 10px solid #127fec;
}
.card-title {
  font-size: 22px;
}
.card-text {
  font-size: 18px;
}
.card-button {
  text-align: center;
}
@media(max-width: 1440px) {
  .main-card {
    padding-top: 5.5rem;
  }
  .card{
    padding: 2rem 2.5rem;
    margin: 0 auto 4.5rem auto;
  }
}
@media(max-width: 760px){
  .card{
    padding: 1rem 1.6rem 2.5rem 1.6rem;
  }
  .card-title{
    font-size: 20px;
  }
}
</style>
