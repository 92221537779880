<template>
  <div class="content labels">
    <h2 class="title" data-aos="fade-down" data-aos-duration="3000">
      Под маркировку попадают
      <br />
      содержащие рекламу:
    </h2>
    <div class="labels-list">
      <div class="labels-item" v-for="item in items" :key="item" data-aos="fade-down" data-aos-duration="2000">
        <img :src="'/labels/' + `${ item.icon }`" class="labels-img" alt="иконка" />
        <p class="labels-text">{{ item.text }}</p>
      </div>
    </div>
    </div>
</template>

<script>
export default {
  name: 'ItemLabels',
  data: () => ({
    items: [
      {
        icon: 'context-icon.svg',
        text: 'Контекстные объявления'
      },
      {
        icon: 'stories-icon.svg',
        text: 'Истории'
      },
      {
        icon: 'micro-icon.svg',
        text: 'Подкасты'
      },
      {
        icon: 'social-icon.svg',
        text: 'Анонсы в соцсетях'
      },
      {
        icon: 'adv-icon.svg',
        text: 'Рекламные посты'
      },
      {
        icon: 'video-icon.svg',
        text: 'Видео трансляции'
      },
      {
        icon: 'play-icon.svg',
        text: 'Видеоролики про ваш продукт'
      },
      {
        icon: 'integration-icon.svg',
        text: 'Контент с интеграциями'
      },
      {
        icon: 'qr-icon.svg',
        text: 'Размещение ссылок и QR-кодов'
      },
      {
        icon: 'blogers-icon.svg',
        text: 'Реклама у блогеров'
      }
    ]
  })
}
</script>

<style scoped>
.labels{
  margin-bottom: 8rem;
}
.labels-list{
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
}
.labels-item {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #c3c6d1;
  border-radius: 25px;
}
.labels-img{
  height: 72px;
  width: 72px;
}
.labels-text {
  font-size: 22px;
  font-weight: 500;
  padding: 1.3rem 3.2rem 1.3rem 2rem;
  margin: 0;
}
@media(max-width: 1440px){
  .labels{
    margin-bottom: 8rem;
  }
  .labels-list{
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .labels-item{
    margin: 0.6rem 0.6rem 0.6rem 0;
  }
  .labels-text{
    padding: 1.3rem 2rem;
  }
}
@media(max-width: 760px){
  .labels{
    margin-bottom: 8rem;
  }
  .labels-list{
    column-gap: 0.2rem;
    row-gap: 0.2rem;
  }
  .labels-item{
    border-radius: 20px;
    padding: 0.4rem;
    margin: 0.3rem 0.5rem 0.3rem 0;
  }
  .labels-img{
    width: 56px;
    height: 56px;
  }
  .labels-text{
    font-size: 16px;
    padding: 0.9rem 0.5rem 0.8rem 1rem;
  }
}
</style>
