<template>
  <metainfo />
  <TopBanner />
  <Fines />
  <ItemLabels />
  <ItemMarkers />
  <QuestionList />
  <MainCard />
  <TheTarrifes />
  <TheInfo />
  <FormInput />
  <TheFooter />
</template>

<script>
import TopBanner from './components/layouts/Header/TopBanner.vue'
import TheFooter from './components/layouts/Footer/TheFooter.vue'
import Fines from './components/layouts/Main/Fines.vue'
import ItemLabels from './components/layouts/Main/ItemLabels.vue'
import ItemMarkers from './components/layouts/Main/ItemMarkers.vue'
import QuestionList from './components/layouts/Main/QuestionList.vue'
import MainCard from './components/layouts/Main/MainCard.vue'
import TheTarrifes from './components/layouts/Main/TheTarrifes.vue'
import TheInfo from './components/layouts/Main/TheInfo.vue'
import FormInput from './components/layouts/Main/FormInput.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'App',
  components: {
    TopBanner,
    TheFooter,
    Fines,
    ItemLabels,
    ItemMarkers,
    QuestionList,
    MainCard,
    TheTarrifes,
    TheInfo,
    FormInput
  },
  setup () {
    useMeta({
      title: 'Медева сервисез',
      meta: [
        {
          name: 'description',
          content: 'Сопровождение деятельности медицинских и научных сообществ, в рамках которой требования к рекламе существенно отличаются от стандартных онлайн-объявлений'
        },
        {
          name: 'msapplication-TileColor',
          content: '#2b5797'
        },
        {
          name: 'theme-color',
          content: '#fff'
        }
      ],
      link: [
        {
          rel: 'manifest',
          href: '/site.webmanifest'
        },
        {
          rel: 'apple-touch-icon',
          href: '/apple-touch-icon.png',
          sizes: '180x180'
        },
        {
          rel: 'icon',
          href: '/favicon-32x32.png',
          type: 'image/png',
          sizes: '32x32'
        },
        {
          rel: 'icon',
          href: '/favicon-16x16.png',
          type: 'image/png',
          sizes: '16x16'
        },
        {
          rel: 'mask-icon',
          href: '/safari-pinned-tab.svg',
          color: '#5bbad5'
        }
      ]
    })
  }
}
</script>
<style>
@import url('@/assets/css/main.css');

#app {
  font-family: "Manrope", monospace;
  color: #2c3e50;
  margin: 0;
}

body {
  margin: 0;
}
</style>
