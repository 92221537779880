<template>
  <div class="questions">
    <div class="content">
      <h2 class="questions-title" data-aos="fade-down" data-aos-duration="3000">
        Для чего
        <span class="underline">необходимо маркировать</span> интернет-рекламу?
      </h2>
      <p class="questions-subtitle" data-aos="fade-right" data-aos-duration="1000">
        С 1 сентября 2022 года в России начал действовать Федеральный закон от
        02.07.2021 года №324-ФЗ, внесший существенные изменения в правила
        размещения рекламы в Интернете. Основное изменение заключается в
        установлении единой системы маркировки рекламы, которая теперь по общему
        правилу стала обязательной для всех рекламных материалов, размещаемых в
        Рунете.
      </p>
      <div v-for="(item, index) in items" :key="index" class="question-item" data-aos="fade-down" data-aos-duration="1000">
        <div
          class="question-grid"
          :class="{opened: item.isOpen, closed: !item.isOpen}"
          @click="toggleAnswer(index)"
        >
          <h3 class="question-title">
            {{ item.title }}
          </h3>
          <img
            src="/open-arrow.svg"
            v-show="!item.isOpen"
            class="toggle-icon"
            alt="открыть"
          />
          <img
            src="/close-arrow.svg"
            v-show="item.isOpen"
            class="toggle-icon"
            alt="закрыть"
          />
        </div>
        <Vue3SlideUpDown v-model="item.isOpen" :duration="3000">
          <div class="answer" v-if="item.isOpen" v-html="item.description"></div>
        </Vue3SlideUpDown>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

const items = ref([])

onMounted(() => {
  axios.get('https://rdapi.medeva.agency/api/v1/reason/').then((res) => {
    items.value = res.data.data
    console.log('Data here:', res.data.data)
  })
})

console.log('Problem here:', items.value)

const toggleAnswer = (index) => {
  items.value[index].isOpen = !items.value[index].isOpen
}
</script>
<script>
export default {
  name: 'ItemQuestions'
}
</script>
<style scoped>
.questions {
  background: #f2f3f7;
}
.questions-title {
  font-weight: 600;
  padding: 9rem 0 2rem 0;
  font-size: 50px;
  line-height: 68.75px;
  color: #333333;
  margin: 0;
}
.questions-subtitle {
  font-size: 18px;
  padding: 2rem 0;
}
.question-item {
  margin: 0 0 2rem 0;
}
.question-item:last-child {
  margin: 0;
}
.question-grid {
  display: grid;
  position: relative;
  z-index: 2;
  grid-template-columns: 11fr 0.9fr;
  align-items: center;
  cursor: pointer;
  background: #fff;
  border-radius: 25px;
  border: 1px solid #c3c6d1;
}
.question-grid img {
  margin: 0 auto;
}
.opened {
  border-radius: 25px 25px 0 0;
  border-bottom: none;
}
.closed {
  animation: transform 12s ease;
}
.opened::after {
  content: '';
  background: #c3c6d1;
  height: 1px;
  width: 99%;
  margin-left: 3rem;
}
.question-title {
  font-size: 22px;
  padding: 1.6rem 2rem 1.6rem 3rem;
  margin: 0;
}
.question-title:hover{
  color: #127FEC;
}
.answer {
  background: #fff;
  font-size: 18px;
  padding: 2rem 3rem;
  font-weight: 500;
  animation: fade 2s ease;
  border-radius: 0 0 25px 25px;
  border: 1px solid #c3c6d1;
  border-top: none;
}
.toggle-icon {
  align-content: right;
}
@keyframes transform {
  0% {
    border-radius: 5px;
  }
  25% {
    border-radius: 10px;
  }
  50% {
    border-radius: 20px;
  }
  75% {
    border-radius: 25px;
  }
}
@keyframes fade {
  0% {
    transform: translateY(-20%);
    opacity: 0;
 }
 50% {
    transform: translateY(0);
    opacity: 1;
 }
}
@media(max-width: 761px) {
  .questions-title {
    font-size: 40px;
    line-height: 55px;
  }
  .question-grid{
    grid-template-columns: 10.5fr 1.5fr;
    align-items: baseline;
  }
}
@media(max-width: 576px) {
  .questions-title{
    font-size: 30px;
    line-height: 41.25px;
  }
  .question-title{
    padding: 1rem 0.1rem 1rem 2rem;
    font-size: 20px;
  }
  .question-grid {
    grid-template-columns: 9.7fr 2.3fr;
  }
  .opened::after {
    width: 106%;
    margin-left: 2rem;
  }
  .toggle-icon {
    top: 0.8rem;
    position: relative;
  }
  .answer{
    font-size: 16px;
  }
}
</style>
