<template>
  <div class="footer-spacing" v-if="footer.data">
    <div class="content">
      <div class="footer-grid block-img-mail">
        <a href="#top"><img src="@/assets/images/top-logo.svg" alt="логотип" /></a>
        <p class="mail"><a href="mailto:ord@medeva.agency">{{ footer.data.email }}</a></p>
      </div>
      <div class="footer-grid">
        <p class="left">
          {{ footer.data.address }}
        </p>
        <div class="right">
         <a href="#top"><p>© 2024 {{ footer.data.name }}</p></a>
         <a href="#">Политика в отношении персональных данных</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import axios from 'axios'

export default {
  name: 'TheFooter',
  setup () {
    const footer = ref([])
    const getFooterData = async () => {
      try {
        const response = await axios.get('https://rdapi.medeva.agency/api/v1/info/')
        if (response.data.status === 'ok') {
          footer.value = response.data
        } else {
          return null
        }
      } catch (err) {
        throw new Error(err)
      }
    }

    getFooterData()
    return {
      footer
    }
  }
}
</script>
<style scoped>
.footer-spacing {
  height: 11rem;
  min-height: 100%;
  width: 100%;
  padding: 1rem 0;
  background: #f2f3f7;
}
.footer-grid{
  display: grid;
  grid-template-columns: 2.5fr 1fr;
}
.block-img-mail{
  margin: 0 0 3rem 0;
}
.mail{
  text-align: right;
  font-size: 22px;
}
.mail a {
  text-decoration: none;
  color: #19374B;
}

/* reverse */
.left{
  order: 2;
  text-align: right;
  font-size: 14px;
}
.right{
  order: 1;
  text-align: left;
  font-size: 14px;
}
.right a{
  color: #19374B;
}
.right a:first-child {
  text-decoration: none;
}
.right p{
  margin: 0 0 0.5rem 0;
}
/* end reverse */
@media(max-width: 1440px){
  .footer-grid{
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 760px){
  .footer-spacing{
    height: 100%;
  }
  .block-img-mail{
    margin: 0;
  }
  .block-img-mail img{
    margin-top: 1rem;
  }
  .block-img-mail p{
    margin: 2rem 0 1rem 0;
  }
  .left, .right{
    order: 1;
    text-align: left;
  }
  .left{
    padding-bottom: 2rem;
  }
  .right{
    margin-bottom: 0.8rem;
  }
  .footer-grid{
    grid-template-columns: 1fr;
  }
  .mail{
    text-align: left;
  }
}
</style>
