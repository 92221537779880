import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { createMetaManager } from 'vue-meta'
import 'aos/dist/aos.css'
import Aos from 'aos'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

const baseUrl = 'https://rdapi.medeva.agency/api'
export const http = axios.create({
  baseURL: baseUrl
})

const app = createApp(App)
app.use(VueReCaptcha, { siteKey: '6LfWQLQpAAAAAB36NGfjEznjydNzE6WV4Gj3TDlp' })
app.use(createMetaManager())
app.use(Aos.init())
app.component('Vue3SlideUpDown', Vue3SlideUpDown)
app.mount('#app')
