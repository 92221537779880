<template>
  <div>
    <div class="content">
      <img src="@/assets/images/top-logo.svg" alt="логотип" />
    </div>
    <Transition>
      <div class="nav" v-if="visible" data-aos="fade-down">
        <div class="content nav-grid">
            <a href="#top"><img src="@/assets/images/top-logo.svg" alt="логотип"/></a>
            <div>
            <a class="button-consult" href="#formInput">ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ</a>
            <a class="button" href="#tariffesTitle">ЗАКАЗАТЬ МАРКИРОВКУ</a>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
import { ref } from 'vue'

export default {
  name: 'TheHeader',
  setup () {
    const visible = ref(false)
    const handleScroll = () => {
      window.removeEventListener('scroll', handleScroll)
      setTimeout(() => {
        visible.value = window.scrollY > 650
        window.addEventListener('scroll', handleScroll, { passive: true })
      }, 300)
    }
    window.addEventListener('scroll', handleScroll, { passive: true })
    return {
      handleScroll,
      visible
    }
  }
}
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.nav {
  position: fixed;
  z-index: 10;
  width: 100%;
  max-height: 80px;
  top: 0;
  background: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}
.nav-grid {
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.button {
  padding: 0.8rem 2rem;
  margin: 0 0.5rem;
  text-align: center;
  font-size: 12px;
}
.button-consult {
  text-align: center;
  text-transform: uppercase;
  padding: 0.8rem 2rem;
  border-radius: 500px;
  border: 1px solid #127fec;
  font-size: 12px;
  margin: 0 0.5rem;
  font-weight: 600;
  color: #127fec;
  cursor: pointer;
  text-decoration: none;
}
.button-consult:hover {
  background: #127fec;
  color: white;
  transition: 0.2s;
}
@media (max-width: 767px) {
  .nav-grid img {
    width: 105px;
  }
  .button {
    display: none;
  }
  .button-consult {
    font-size: 10px;
    color: white;
    background: #127fec;
    padding: 0.8rem 1rem;
  }
}
@media (max-width: 360px) {
  .nav-grid {
    gap: 0;
  }
  .nav-grid img {
    width: 89px;
    padding-top: 0.8rem;
  }
  .button-consult {
    font-size: 8px;
    padding: 0.8rem 1rem;
    margin: 0;
  }
}
</style>
