<template>
  <div class="markers">
    <div class="content">
      <h2 class="markers-title" data-aos="fade-down" data-aos-duration="3000">
        Какие материалы должны маркировать
        <span class="blue underline"> медицинские ассоциации</span>?
      </h2>
      <div class="markers-grid" data-aos="fade-right" data-aos-duration="1000">
        <div class="markers-text-grid">
          <img src="/markers/live-icon.svg" class="markers-icon" alt="иконка" />
          <p class="markers-text">
            Прямые трансляции вебинаров или конференций, которые проходят при
            поддержке партнера и содержат в себе рекламу
          </p>
        </div>
        <div class="markers-text-grid">
          <img
            src="/markers/publication-icon.svg"
            class="markers-icon markers-pl"
            alt="иконка"
          />
          <p class="markers-text">
            Рекламные публикации у блогеров, в тематических пабликах (в том
            числе в telegram каналах), на тематических порталах или СМИ
          </p>
        </div>
      </div>
      <div class="markers-grid markers-block" data-aos="fade-right" data-aos-duration="1000">
        <div class="markers-text-grid">
          <img src="/markers/img-icon.svg" class="markers-icon" alt="иконка" />
          <p class="markers-text">
            Продуктовые посты, которые описывают преимущество вашего
            товара/услуги и стимулируют желание к приобретению
          </p>
        </div>
        <div class="markers-text-grid">
          <img src="/markers/browser-icon.svg" class="markers-icon" alt="иконка" />
          <p class="markers-text">
            Рекламные баннеры на Вашем сайте, рекламные модули в брошюрах и
            любых иных документах, размещенных Вами в интернете
          </p>
        </div>
      </div>
      <div class="markers-button"><a href="#tariffesTitle" class="button" data-aos="fade-right" data-aos-duration="1000">Заказать маркировку</a></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ItemMarkers'
}
</script>

<style scoped>
.markers {
  background: #f2f3f7;
  padding-top: 7.5rem;
}
.markers-title {
  font-weight: 600;
  font-size: 50px;
  line-height: 68.75px;
  color: #333333;
  margin-bottom: 4rem;
  max-width: 70rem;
}
.markers-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}
.markers-text-grid {
  display: grid;
  grid-template-columns: 6rem 1fr;
}
.markers-icon {
  padding-right: 2rem;
}
.markers-text {
  font-size: 18px;
  margin: 1rem 0;
  color: #19374b;
}
.markers-block {
  margin-bottom: 4rem;
}
.markers-button {
  text-align: center;
}
@media(max-width: 761px) {
  .markers-title {
    font-size: 40px;
    line-height: 55px;
  }
  .markers-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 576px) {
  .markers-grid {
    grid-template-columns: 1fr;
  }
  .markers-text-grid{
    margin-top: 2rem;
  }
  .markers-title{
    font-size: 30px;
    line-height: 41.25px;
  }
  .markers-text{
    margin: 0.5rem 0;
  }
}
</style>
