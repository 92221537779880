<template>
  <div class="top-container">
    <TheHeader />
    <div class="content top" data-aos="fade-right" data-aos-duration="3000">
      <h1 class="top-title">Маркировка медицинской интернет-рекламы
        <br>
        <span class="blue">«под ключ»</span>
      </h1>
      <p class="top-subtitle">
        Мы берём на себя рутинные процессы маркировки, обеспечивая соблюдение
        законодательства о рекламе
      </p>
      <a href="#tariffesTitle" class="button">Заказать маркировку</a>
    </div>
  </div>
</template>

<script>
import TheHeader from './TheHeader.vue'

export default {
  name: 'TopBanner',
  components: {
    TheHeader
  }
}
</script>

<style scoped>
.top-container {
  min-width: 100%;
  max-height: 45rem;
  height: 45rem;
  min-height: 100%;
  width: 100%;
  padding: 1rem 0;
  background: url("@/assets/images/header__top.jpg") no-repeat top right 80%/100%;
}
.top {
  margin-top: 8rem;
}
.top-title {
  color: #333333 !important;
  font-size: 46px;
  max-width: 40rem;
  font-weight: 600;
  line-height: 63.25px;
}
.top-subtitle {
  color: #333333 !important;
  width: 32rem;
  font-size: 18px;
  padding: 0 0 4rem 0;
  line-height: 32px;
}
@media (max-width: 1800px) {
  .top-container {
    background: url("@/assets/images/top-tablet.jpg") no-repeat top right #F2F3F7;
    height: 40rem;
  }
}
@media(max-width: 1440px){
  .top{
    margin-top: 4rem;
  }
  .top-title{
    font-size: 40px;
    line-height: 55px;
  }
  .top-subtitle{
    width: 25rem;
  }
}
@media(max-width: 576px){
  .top-container{
    background: url("@/assets/images/top-mobile.jpg") no-repeat bottom right 100%/100% #DBE7F5;
    height: 45rem;
  }
  .top{
    margin-top: 3rem;
  }
  .top-title{
    font-size: 30px;
    line-height: 41.25px;
  }
  .top-subtitle{
    max-width: 20rem;
    width: initial;
    font-size: 16px;
    padding: 2rem 0 3rem 0;
  }
}
</style>
