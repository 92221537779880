<template>
  <div>
  <div class="application">
    <h2 class="title" data-aos="fade-down" data-aos-duration="3000">
      Остались вопросы? <span class="blue">Напишите нам</span>
    </h2>
    <div class="application-head">
      <div>
        <p class="application-subtitle">
          Мы возьмём на себя рутинные процессы маркировки, обеспечивая
          соблюдение законодательства о рекламе
        </p>
        <div class="form-subtitle">
          <p>
            Оставьте заявку, чтобы проконсультироваться по вопросу маркировки
            интернет-рекламы
          </p>
          <p>Свяжемся с вами в самое ближайшее время</p>
        </div>
      </div>
      <div class="sup-block">
        <div class="sup-banner">
          <p>Реклама ООО «Ваш рекламодатель»</p>
          <p>ИНН 1231231231</p>
          <p>ERID: j1SVHyf65BHW4</p>
        </div>
      </div>
    </div>
    <form class="form-question">
      <div v-if="error" :class="[{ field: v$.$error }, { fieldError: v$.$error} ]" class="fieldError"><img src="@/assets/images/form-alert.svg" alt="(!)">Необходимо заполнить все обязательные поля</div>
      <div class="input-grid">
        <input :class="{ 'field--error': v$.name.$error }" type="text" placeholder="Ваше имя*" v-model="form.name" />
        <div class="small-place">
          <input :class="{ 'field--error': v$.email.$error }" class="mail" type="email" placeholder="E-mail*" v-model="form.email" />
          <small v-if="v$.email.$error">E-mail введён не корректно</small>
        </div>
        <div class="small-place">
          <input :class="{ 'field--error': v$.phone.$error }" class="phone" type="tel" placeholder="Телефон*" v-model="form.phone" />
          <small v-if="v$.phone.$error">Телефон введён не корректно</small>
        </div>
        <textarea
          class="textarea"
          cols="40"
          rows="4"
          type="text"
          name="question"
          placeholder="Ваш вопрос"
          v-model="form.question"
        />
      </div>
    <div class="form-button">
        <div>
        <label class="checkbox">
        <input type="checkbox" v-model="form.isAgreement" :true-value="1" :false-value="0" />
        <div class="checkbox__checkmark" :class="{'checkbox--error': v$.isAgreement.$error }"></div>
        <div class="checkbox__body">
          Согласен(а) на обработку <a href="">персональных данных</a>
        </div>
        </label><br>
        <button class="button" @click.prevent="sendData" @click="modal === true">Оставить заявку</button>
        </div>
    </div>
    </form>
  </div>
  <div class="modal" v-if="modal === true" data-aos="zoom-in" data-aos-duration="1000">
      <div class="modal-window">
        <img class="close" src="/close.svg" alt="иконка крестика" @click="modal = false">
        <div class="modal-text">
        <p>Спасибо, Ваша заявка отправлена</p>
        <a class="button" @click="modal = false" href="#">На главную</a>
      </div>
      </div>
    </div>
</div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, sameAs, minValue, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useReCaptcha } from 'vue-recaptcha-v3'
import axios from 'axios'

export default {
  name: 'FormInput',
  setup () {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
    const error = ref(false)
    const modal = ref(false)
    const form = reactive({
      name: '',
      email: '',
      phone: '',
      question: '',
      isAgreement: ''
    })
    const rules = {
      name: { required, minLength: minLength(3) },
      email: { required, email },
      phone: { required, minValue: minValue(11) },
      isAgreement: { sameAs: sameAs(1) }
    }
    const v$ = useVuelidate(rules, form)

    const sendData = async () => {
      await recaptchaLoaded()
      const token = await executeRecaptcha()
      v$.value.$touch()
      if (v$.value.$error) {
        error.value = true
        return
      }
      try {
        const response = await axios.post('https://rdapi.medeva.agency/api/v1/form/create-request/', {
          name: form.name,
          email: form.email,
          phone: form.phone,
          text: form.question,
          is_terms: form.isAgreement,
          retoken: token
        }, {
          headers: {
            'Content-type': 'application/json'
          }
        })
        form.value = response.data
        if (response.data.status === 'ok') {
          form.name = ''
          form.email = ''
          form.phone = ''
          form.question = ''
          form.isAgreement = ''
          error.value = false
          modal.value = true
          v$.value.$reset()
        }
      } catch (err) {
        throw new Error(err)
      }
    }

    return {
      form,
      v$,
      sendData,
      error,
      modal
    }
  }
}
</script>
<style scoped>
/* modal*/
.modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.modal-window {
  background: url("@/assets/images/send.jpg") no-repeat top 20% center white;
  max-width: 570px;
  width: 100%;
  max-height: 412px;
  height: 100%;
  padding: 2rem;
  margin: 0 auto;
  border-radius: 30px;
}
.modal-window * {
  display: block;
}
.close {
  float: right;
  cursor: pointer;
}
.modal-window p, .modal-window a {
  text-align: center;
}
.modal-window p {
  font-size: 22px;
  font-weight: 600;
  padding: 2rem 0;
}
.modal-text {
  padding-top: 13rem;
}
/* end modal */
/**/
.fieldError {
  display: none;
}
.field {
  background: white;
  border-radius: 50px;
  color: red;
  padding: 0.5rem 1rem;
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  width: max-content;
}
.field img {
  margin-right: 1rem;
}
.form-question .field--error {
  background: url("@/assets/images/form-alert.svg") no-repeat right/18% 40% white;
}
.checkbox .checkbox--error {
  background: red;
}
.form-question .field--error::placeholder {
  color: red;
}
.form-question .field--error {
  color: red;
}
/**/
.application {
  max-width: 1310px;
  margin: 0 auto 8rem auto;
}
.application h2 {
  padding-left: 4.2rem;
}
.application-head {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.application-subtitle {
  font-size: 22px;
  padding: 0 0 0.7rem 4.2rem;
  box-shadow: 8rem 14.2rem 0 #127fec;
}
.sup-block {
  margin: 0 auto 0 0;
  padding-left: 4.5rem;
  border-radius: 0 0 0 30px;
  background: url("@/assets/images/form-sup.jpg") no-repeat bottom right 20% white;
}
.sup-banner {
  position: relative;
  background: rgba(255, 255, 255, 70%);
  border: 1.5px #f5f5f9;
  border-radius: 15px;
  padding: 1rem;
  box-shadow: 0px 25px 30px -20px #00000099;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  top: 10rem;
}
.sup-banner p {
  font-size: 16px;
}
.form-subtitle {
  background: #127fec;
  padding: 3rem 9rem 2rem 5rem;
  color: white;
  border-radius: 30px 30px 0 0;
}
.form-subtitle p:first-child {
  font-weight: 600;
  font-size: 26px;
}
.form-subtitle p:last-child {
  padding: 1rem 0 0 0;
}
.form-question {
  background: #127fec;
  padding: 2rem 4rem 0 4rem;
  color: white;
  border-radius: 0 30px 30px 30px;
}
.input-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2rem;
}
.form-question input, textarea {
  border-radius: 10px;
  border: none;
  padding: 0.5rem 2rem;
  font-size: 16px;
  height: 3rem;
}
.form-question input, textarea:focus {
  outline: none;
}
.small-place {
  display: grid;
}
.small-place small {
  padding: 0 0.8rem;
  color: white;
}
.textarea {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  font-family: 'Manrope';
  align-content: center;
}
.form-question button {
  border: 2px solid white;
  margin: 3rem 0;
  font-weight: 600;
}
.form-button{
  margin: 2rem 0 0 0;
  text-align: center;
}
.button {
  margin: 1.5rem 0 3.5rem 0 !important;
}
/**/
.checkbox {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
  border: solid #127fec;
  position: absolute;
  left: 6px;
  top: 2px;
  width: 4px;
  height: 9px;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.checkbox input:checked ~ .checkbox__checkmark{
  background: #eee;
}
.checkbox .checkbox__checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 2px solid #eee;
  border-radius: 4px;
}
.checkbox .checkbox__checkmark:after {
  content: "";
  opacity: 0;
  transition: opacity 0.25s ease;
}
.checkbox .checkbox__body {
  color: white;
  font-size: 14px;
}
.checkbox__body a {
  color: white;
}
/**/
.captcha {
  background: white;
  color: black;
  text-align: left;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  width: 260px;
  height: 90px;
  line-height: 19.25px;
}
.captcha-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 40px;
  top: 0.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.captcha-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.captcha-checkbox input:checked ~ .captcha-checkbox__checkmark:after {
  opacity: 1;
  border: solid white;
  position: absolute;
  left: 8px;
  top: 4px;
  width: 6px;
  height: 11px;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.captcha-checkbox input:checked ~ .captcha-checkbox__checkmark{
  background: #5183FE;
}
.captcha-checkbox .captcha-checkbox__checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 24px;
  width: 24px;
  background: #D8D9DE;
  border-radius: 4px;
}
.captcha-checkbox .captcha-checkbox__checkmark:after {
  content: "";
  opacity: 0;
  transition: opacity 0.25s ease;
}
.captcha-checkbox__body {
  color: black;
  padding-top: 0.2rem;
  font-size: 14px;
  font-weight: 600;
}
.captcha-checkbox__body span, small {
  font-weight: 400;
  color: #656565;
}
.captcha-checkbox__body span {
  font-size: 12px;
}
.captcha-checkbox__body small {
  font-size: 10px;
  line-height: 30px;
}
/**/
@media(max-width: 1286px){
  .application{
    max-width: 1170px;
  }
  .application-subtitle{
    box-shadow: 8rem 17rem 0 #127fec;
  }
  .sup-block{
    padding-left: 2rem;
  }
  .form-question {
    padding: 2rem 2.5rem;
    margin: 0 1.5rem;
  }
  .form-subtitle{
    margin: 0 0 0 1.5rem;
  }
  .sup-banner{
    top: 13rem;
  }
}
@media(max-width: 1074px) {
  .sup-block {
    background-size: 80%;
  }
  .application-subtitle {
    box-shadow: 8rem 20rem 0 #127fec;
  }
  .sup-banner{
    top: 17.5rem;
  }
  .sup-banner p {
    font-size: 10px;
  }
}
@media(max-width: 992px) {
  .application h2 {
    padding-left: 1.3rem;
  }
  .application-subtitle {
    padding: 0 1.3rem 0.7rem 1.3rem;
    box-shadow: 10rem 15.5rem 0 #127fec;
  }
  .form-subtitle {
    padding: 3rem 2.5rem;
  }
  .input-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .mail, .phone {
    grid-row-start: 2;
  }
  .textarea {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .sup-block {
    padding: 1rem 1.5rem 1rem 1.5rem;
    background-size: 60%;
    background-position: bottom right 50%;
  }
  .sup-banner {
    padding: 0.5rem 0.7rem;
    top: 17.1rem;
  }
  .checkbox {
    top: 1rem;
  }
  .form-subtitle p:first-child {
    font-size: 21px;
  }
  .small-place {
    grid-template-rows: 0.1fr 0.1fr;
  }
  .small-place input {
    grid-row-start: 1;
  }
  .small-place small {
    grid-row-start: 2;
  }
}
@media(max-width: 767px) {
  .input-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 0.95rem;
  }
  .application-head {
    grid-template-columns: 1fr;
  }
  .checkbox {
    top: 0;
  }
  .sup-block {
    display: none;
  }
  .application-subtitle {
    box-shadow: none;
  }
  .form-subtitle{
    margin: 0 1.5rem;
  }
  .form-question {
    border-radius: 0 0 30px 30px;
    padding: 0.1rem 1.5rem 2rem 1.5rem;
  }
  .mail {
    grid-row-start: 2;
  }
  .phone{
    grid-row-start: 3;
  }
  .textarea{
    grid-row-start: 4;
    grid-column: 1;
    height: 6rem !important;
    align-content: baseline;
  }
}
@media(max-width: 678px) {
  .modal-window {
    margin: 0 1.5rem;
  }
}
@media(max-width:576px) {
  .form-question {
    margin-top: -0.1rem;
  }
  .field {
    max-width: 100%;
    width: initial;
  }
  .form-subtitle {
    padding: 1.5rem 1.5rem;
  }
  .form-subtitle:first-child {
    font-size: 21px;
  }
  .checkbox__body {
    max-width: 12rem;
    text-align: left;
  }
  .checkbox {
    margin-bottom: 1rem;
  }
}
@media (max-width: 360px) {
  .title {
    padding-left: 0;
  }
  .form-subtitle p {
    font-size: 16px !important;
  }
  .small-place {
    max-width: 5rem;
  }
  .form-question input, textarea {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }
}
</style>
